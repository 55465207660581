import React, { useEffect, useState } from 'react'
import { css } from 'emotion'
import { getMessageParam, useLocalization } from '@productivity/i18n'
import { addTestId } from '@productivity/utils'
import { primary, Spinner, Text } from '@phoenix/all'
import { testIds, messageKeys } from '../utils/constants'

export const bar = css`
  width: 100%;
  height: 56px;
  background-color: ${primary.white()};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const text = css`
  font-weight: bold;
  color: ${primary.gray()};
`

export default function FetchingBar() {
  const [shouldShow, setShouldShow] = useState(false)
  const [fetchMsg] = useLocalization(...getMessageParam(messageKeys.fetchMsg))

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldShow(true)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  if (shouldShow === false) return null

  return (
    <div className={bar} {...addTestId(testIds.fetchingBar)}>
      <Spinner>
        <Text.Small className={text}>{fetchMsg}</Text.Small>
      </Spinner>
    </div>
  )
}
