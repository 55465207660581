import React from 'react'
import { string } from 'prop-types'
import {
  Announcement,
  Document,
  Iteration,
  Note,
  OpTask,
  PortalSection,
  PortalTab,
  Portfolio,
  Program,
  Project,
  Proof,
  ProofApproval,
  Task,
  Team,
  Timesheet,
  User,
} from 'workfront-objcodes'
import DashboardIcon from 'phoenix-icons/dist/DashboardIcon.js'
import ReportIcon from 'phoenix-icons/dist/ReportIcon.js'
import AgileIcon from 'phoenix-icons/dist/AgileIcon.js'
import TimesheetIcon from 'phoenix-icons/dist/TimesheetIcon.js'
import AnnouncementsIcon from 'phoenix-icons/dist/AnnouncementsIcon.js'
import ProofIcon from 'phoenix-icons/dist/ProofIcon.js'
import PortfolioIcon from 'phoenix-icons/dist/PortfolioIcon.js'
import ProgramIcon from 'phoenix-icons/dist/ProgramIcon.js'
import GroupIcon from 'phoenix-icons/dist/GroupIcon.js'
import UserIcon from 'phoenix-icons/dist/UserIcon.js'
import IssueIcon from 'phoenix-icons/dist/IssueIcon.js'
import TaskIcon from 'phoenix-icons/dist/TaskIcon.js'
import ProjectIcon from 'phoenix-icons/dist/ProjectIcon.js'
import DocumentIcon from 'phoenix-icons/dist/DocumentIcon.js'

const iconMap = {
  [Announcement]: AnnouncementsIcon,
  [Document]: DocumentIcon,
  [Iteration]: AgileIcon,
  [Note]: null,
  [OpTask]: IssueIcon,
  [PortalSection]: ReportIcon,
  [PortalTab]: DashboardIcon,
  [Portfolio]: PortfolioIcon,
  [Program]: ProgramIcon,
  [Project]: ProjectIcon,
  [Proof]: ProofIcon,
  [ProofApproval]: null,
  [Task]: TaskIcon,
  [Team]: GroupIcon,
  [Timesheet]: TimesheetIcon,
  [User]: UserIcon,
}

const eventMap = {
  //NOTE CODES
  HRC: null,
  M: null,
  SUP: null,
  T: null,
  WIC: null,
  WRR: null,
  TCA: TimesheetIcon,
  TCO: TimesheetIcon,
  UC: UserIcon,
  //ANNOUCEMENT CODES
  ANC: AnnouncementsIcon,
  //JOURNAL CODES
  A: null,
  CDC: null,
  PDC: null,
  R: null,
  TSA: TimesheetIcon,
  TSO: TimesheetIcon,
  TSR: TimesheetIcon,
  TSS: TimesheetIcon,
  //ASSIGNMENT CODES
  ASN: null,
  AST: null,
  //AWAITING APPROVAL CODES
  AWT: null,
  //DOCUMENT APPROVAL CODES
  DAA: DocumentIcon,
  DAC: DocumentIcon,
  DAH: DocumentIcon,
  DAR: DocumentIcon,
  DAU: DocumentIcon,
  DAP: ProofIcon,
  DUP: ProofIcon,
  DUV: ProofIcon,
  //PROOF APPROVAL CODES
  PAA: ProofIcon,
  PAE: ProofIcon,
  //LIKE CODES
  LKN: null,
  LKR: UserIcon,
  LKE: UserIcon,
  //DOCUMENT SHARE CODES:
  DST: DocumentIcon,
  DSU: DocumentIcon,
  DSP: ProofIcon,
  //ACCESS REQUEST
  TA: null,
  CSA: null,
  //DOCUMENT REQUEST
  DRC: DocumentIcon,
  DRF: DocumentIcon,
  DRR: UserIcon,
}

const iconProps = {
  height: 16,
  width: 16,
}

export default function ObjectIcon({ eventType, itemObjCode }) {
  const EventIcon = eventMap[eventType]
  if (EventIcon != null) {
    return <EventIcon {...iconProps} />
  }

  const ObjIcon = iconMap[itemObjCode]
  if (ObjIcon != null) {
    return <ObjIcon {...iconProps} />
  }

  return <IssueIcon {...iconProps} />
}

ObjectIcon.propTypes = { eventType: string, itemObjCode: string }
