import {
  AccessRequest,
  Announcement,
  Assignment,
  AwaitingApproval,
  DocumentApproval,
  DocumentRequest,
  DocumentShare,
  DocumentVersion,
  JournalEntry,
  Like,
  Note,
  ProofApproval,
} from 'workfront-objcodes'
import parseAccessRequest from './access-request'
import parseAnnouncement from './announcement'
import parseAssignment from './assignment'
import parseAwaitingApproval from './awaiting-approval'
import parseDocumentApproval from './document-approval'
import parseDocumentRequest from './document-request'
import parseDocumentShare from './document-share'
import parseJournal from './journal'
import parseLike from './like'
import parseNote from './note'
import parseProofApproval from './proof-approval'
import parseDocumentVersionApproval from './document-version-approval'

// Parsers for supported objCodes
export const supportedParsers = {
  [AccessRequest]: parseAccessRequest,
  [Announcement]: parseAnnouncement,
  [Assignment]: parseAssignment,
  [AwaitingApproval]: parseAwaitingApproval,
  [DocumentApproval]: parseDocumentApproval,
  [DocumentRequest]: parseDocumentRequest,
  [DocumentShare]: parseDocumentShare,
  [DocumentVersion]: parseDocumentVersionApproval,
  [JournalEntry]: parseJournal,
  [Like]: parseLike,
  [Note]: parseNote,
  [ProofApproval]: parseProofApproval,
}
