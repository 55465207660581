import { getObjectLink } from '@wf-mfe/navigation'
import parseParent from './parent'

export default function parseLike(like, notification) {
  const { ID: commentID } = notification
  const { ID, objCode } = like

  const item = {
    ...like,
    link: `${getObjectLink({ ID, objCode }).to}/updates?commentID=${commentID}`,
  }

  // Parse parent if it exists
  if (like.parent) item.parent = parseParent(like.parent)

  return item
}
