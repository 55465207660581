import adobeWorkfrontLionDataUri from 'phoenix-icons/dist/adobeWorkfrontLionDataUri.js'
import { getObjectLink } from '@wf-mfe/navigation'

import { LION_ICON_DIMENSIONS } from '../constants'
import parseParent from './parent'

export default function parseAccessRequest(item, note) {
  const { ...owner } = item.owner
  const formattedItem = {
    ...item,
    link: getObjectLink({ ID: item.ID, objCode: item.objCode }).to,
    owner: {
      ...owner,
      ...(note.eventType === 'CSA' && { avatar: adobeWorkfrontLionDataUri(LION_ICON_DIMENSIONS) }),
    },
  }

  // Parse parent if it exists
  if (item.parent) formattedItem.parent = parseParent(item.parent)

  return formattedItem
}
