import React from 'react'
import { bool, number } from 'prop-types'
import { css, cx } from 'emotion'
import { useUpdateAtom } from 'jotai/utils'
import { primary, Button } from '@phoenix/all'
import { getMessageParam, useLocalization } from '@productivity/i18n'
import { addTestId } from '@productivity/utils'
import { markNotificationReadAtom } from '../atoms/notifications'
import { messageKeys, testIds } from '../utils/constants'

const notificationsHeader = css`
  position: sticky;
  top: 0;
  height: 2rem;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-end;
  background-color: ${primary.gray(100)};

  & button {
    padding: 0;
    height: auto;
    font-weight: 500;
    line-height: 1;
  }

  & button:hover {
    text-decoration: none;
  }
`

const headerMargin = css`
  margin-bottom: 8px;
`

export default function MarkAllHeader({ condensedDisplay, total }) {
  const markNotificationRead = useUpdateAtom(markNotificationReadAtom)
  const [markAllMsg] = useLocalization(...getMessageParam(messageKeys.markAllSeen))
  return (
    <header
      className={cx(notificationsHeader, condensedDisplay && headerMargin)}
      {...addTestId(testIds.notificationsMarkAllHeader)}
    >
      <Button
        {...addTestId(testIds.notificationsMarkAll)}
        style={{ backgroundColor: 'transparent', border: 0 }}
        text
        onClick={() => {
          if (total && total > 0) {
            markNotificationRead(['*'])
          }
        }}
      >
        {markAllMsg}
      </Button>
    </header>
  )
}

MarkAllHeader.propTypes = { condensedDisplay: bool, total: number.isRequired }
