import { arrayOf, bool, shape, string } from 'prop-types'

export const documentShape = shape({ ID: string })

export const ownerShape = shape({
  avatar: string,
  name: string,
})

export const parentShape = shape({
  link: string,
  title: string,
})

export const itemShape = shape({
  documents: arrayOf(documentShape),
  link: string,
  objCode: string,
  owner: ownerShape,
  parent: parentShape,
  text: string,
  title: string,
})

export const notificationShape = shape({
  entryDate: string,
  eventType: string,
  ID: string,
  isRead: bool,
  item: itemShape,
  notificationID: string,
})
