import { wfetch } from '@wf-mfe/api'
import { NOTIFICATIONS_LIMIT } from '../utils/constants'

export function getNotifications(limit = NOTIFICATIONS_LIMIT, includeAll = false) {
  return wfetch(`/internal/notification/myNotifications?limit=${limit}&includeAll=${includeAll}`)
}

export function markNotificationsRead(IDs) {
  return wfetch(`/internal/notification/markAsSeen?ID=${IDs.join(',')}`, {
    method: 'POST',
  })
}

// Use legacy endpoints for actions that were not added to the new controller
export function markAllNotificationsRead() {
  return wfetch('/attask/api-internal/USRNOT?action=acknowledgeAll&method=PUT')
}

export function markNotificationUnread(id) {
  return wfetch(`/attask/api-internal/USRNOT?action=unacknowledge&ID=${id}&method=PUT`)
}
