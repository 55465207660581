import { getObjectLink } from '@wf-mfe/navigation'
import parseParent from './parent'

export default function parseJournal(journal) {
  const { ID, objCode } = journal

  let linkParams = ''
  if (objCode !== 'TSHET') {
    linkParams = '/updates'
  }

  const item = {
    ...journal,
    link: `${getObjectLink({ ID, objCode }).to}${linkParams}`,
  }

  // Parse parent if it exists
  if (journal.parent) item.parent = parseParent(journal.parent)

  return item
}
