import React from 'react'
import { bool, number, array } from 'prop-types'
import { Provider } from 'jotai'
import { ErrorBoundary } from '../utils/logger'
import NotificationHub from './notification-hub'
import {
  notificationsAtom,
  notificationFetchStateAtom,
  totalUnreadNotificationsAtom,
} from '../atoms/notifications'
import { fetchStates } from '../utils/constants'
import parseNotification from '../utils/parse-notification'

export default function NotificationHubRouter({ defaultIsOpen, total, notifications }) {
  const initialValues = [
    [notificationFetchStateAtom, defaultIsOpen ? fetchStates.SUCCESS : fetchStates.LOADING],
    [totalUnreadNotificationsAtom, total],
    [notificationsAtom, notifications?.map(parseNotification)],
  ]

  return (
    <ErrorBoundary>
      <Provider initialValues={initialValues}>
        <NotificationHub defaultIsOpen={defaultIsOpen} />
      </Provider>
    </ErrorBoundary>
  )
}

NotificationHubRouter.propTypes = {
  defaultIsOpen: bool,
  total: number,
  notifications: array,
}
