import { supportedParsers } from './parsers'
export default function parseNotification(notification) {
  const parser = supportedParsers[notification.objCode]

  // Clone notification
  return {
    ...notification,
    item: parser == null ? null : parser(notification.item, notification),
  }
}
