import { css } from 'emotion'
import { alerts, primary } from '@phoenix/all'
import { pxToRem } from '@productivity/utils'

export const wrapper = css`
  display: flex;
  flex-wrap: nowrap;
  padding: 16px;
  color: ${primary.gray(600)};
  background-color: ${primary.white()};
  font-size: 1rem;
  font-weight: normal;

  &:hover {
    background-color: ${primary.blue(50)};
  }

  &:visited,
  &:focus,
  &:hover {
    color: ${primary.gray(600)};
  }
`

export const condensedPadding = css`
  padding: 8px 16px;
`

const unreadIconSize = '8px'
const unreadButtonSize = '16px'

export const readContainer = css`
  border-radius: 50%;
  height: ${unreadButtonSize};
  margin-right: ${unreadIconSize};
  padding-top: 12px;
  position: relative;
  width: ${unreadButtonSize};
`

export const readIcon = css`
  background-color: ${primary.gray(200)};
  border-radius: 50%;
  display: block;
  height: ${unreadIconSize};
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${unreadIconSize};
`

export const unreadIcon = css`
  background-color: ${primary.blue(400)};
`

const avatarSize = '36px'

export const avatar = css`
  width: ${avatarSize};
  height: ${avatarSize};
`

export const supportAvatar = css`
  margin-left: 1rem;
`

export const parent = css`
  font-size: ${pxToRem(12)};
  color: ${primary.gray(400)};
  font-weight: normal;
  padding: 0;
  max-width: ${pxToRem(208)};

  /* stylelint-disable-next-line selector-max-universal */
  & > * {
    pointer-events: none;
  }
  &:visited {
    color: ${primary.gray(400)};
  }
`

export const contentHeader = css`
  display: flex;
  justify-content: space-between;
`

export const content = css`
  width: ${pxToRem(328)};
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex: 1;
  margin-left: 6px;
`

export const title = css`
  font-weight: 600;
  color: ${primary.blue(400)};
  padding: 0;
  display: block;
  overflow-wrap: break-word;
  max-width: ${pxToRem(260)};

  /* stylelint-disable-next-line selector-max-universal */
  & > * {
    pointer-events: none;
  }
  &:visited {
    color: ${primary.blue(400)};
  }
`

export const supportTitle = css`
  color: ${alerts.error()};
`

export const footer = css`
  display: flex;
  align-items: center;
  margin-top: 8px;
  justify-content: space-between;
`
export const type = css`
  display: inline-flex;
  align-items: center;
`

export const footerMessage = css`
  width: 65%;
`

export const footerOwner = css`
  width: 35%;
`

export const icon = css`
  width: 16px;
  margin-right: 4px;
`

export const from = css`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: 'row';
  &:before {
    content: '·';
    display: inline-block;
    margin: -16px 8px;
    top: -2px;
    position: relative;
    font-size: xx-large;
  }
`

export const img = css`
  display: block;
  max-width: 85px;
  max-height: 45px;
  width: auto;
  height: auto;
  border-radius: 3px;
`
