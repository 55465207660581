import { differenceInHours } from 'date-fns'

export function parseDate(date, options) {
  const locale = navigator.language
  const formattedDate = new Date(date)
  let finalOptions = { ...options }
  return new Intl.DateTimeFormat(locale, finalOptions).format(formattedDate)
}

export function formatTimestamp(day, yesterdayLabel) {
  const now = new Date()
  let hours = -differenceInHours(day, now)
  if (hours < 24) {
    return parseDate(day, { hour: 'numeric', minute: 'numeric' })
  } else if (hours < 48) {
    return yesterdayLabel
  } else if (hours <= 168) {
    let days = hours / 24
    days = Math.ceil(days)
    if (days === 7) {
      return '1w'
    }
    return `${days}d`
  } else {
    return parseDate(day, { month: 'short', day: 'numeric' })
  }
}
