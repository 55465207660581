/**
 * Fires click handler unless text is being selected
 * @param handler - Click event handler
 */
export default function onClickUnlessSelection(handler) {
  return (ev) => {
    const selection = window.getSelection()
    /**
     * Selection.type will either be 'caret' or 'range' depending on the progress.
     * isCollapsed will be true if no text was selected.
     * 'None' will be the type if no text was clicked.
     */
    if (selection && selection.type !== 'None' && selection.isCollapsed === false) {
      ev.stopPropagation()
      return
    }
    return handler(ev)
  }
}
