import { useEffect, useState } from 'react'
import {
  Announcement,
  Document,
  DocumentVersion,
  Iteration,
  Note,
  OpTask,
  PortalSection,
  PortalTab,
  Portfolio,
  Program,
  Project,
  Proof,
  ProofApproval,
  RateCard,
  Task,
  Team,
  Template,
  Timesheet,
  User,
} from 'workfront-objcodes'
import { fallbackMap, localizationClient } from '@productivity/i18n'
import { messageKeys } from './constants'
import { logger } from './logger'

const eventMap = {
  //NOTE CODES
  HRC: messageKeys.helpRequestComment,
  M: messageKeys.objectComment,
  SUP: messageKeys.objectComment,
  T: messageKeys.objectComment,
  WIC: messageKeys.objectComment,
  WRR: messageKeys.objectComment,
  TCA: messageKeys.timesheetComment,
  TCO: messageKeys.timesheetComment,
  UC: messageKeys.comment,
  //ANNOUCEMENT CODES
  ANC: messageKeys.announcement,
  //ASSIGNMENT
  ASN: messageKeys.assignmentToUser,
  AST: messageKeys.assignmentToTeam,
  // ACCESS REQUESTS
  TA: messageKeys.accessRequest,
  // DOCUMENT REQUESTS
  DRC: messageKeys.requestedDocumentCanceled,
  DRF: messageKeys.requestedDocumentFulfilled,
  DRR: messageKeys.requestedDocument,
  DUV: messageKeys.documentApprovalAddUserView,
  // DOCUMENT SHARE
  DST: messageKeys.documentSharedWithTeam,
  DSU: messageKeys.documentSharedWithYou,
  DSP: messageKeys.proofSharedWithYou,
  // JOURNAL ENTRY CODES
  CDC: messageKeys.commitDateChanged,
  PDC: messageKeys.plannedDateChanged,
  TSA: messageKeys.timesheetApproved,
  TSO: messageKeys.timesheetReopened,
  TSR: messageKeys.timesheetRejected,
  TSS: messageKeys.timesheetSubmitted,
  // LIKES
  LKN: messageKeys.likes,
  // Customer Support Request
  CSA: messageKeys.supportRequest,
  //WAITING APPROVAL
  AWT: messageKeys.waitingApproval,
  // DOCUMENT APPROVAL
  DAU: messageKeys.documentApproval,
  DAA: messageKeys.documentApprovalApproved,
  DAR: messageKeys.documentApprovalRejected,
  DAC: messageKeys.documentApprovalCancelled,
  DAH: messageKeys.documentApprovalWithChanges,
  // DOCUMENT PROOF APPROVAL
  PAE: messageKeys.proofAddApproverNoRequester,
  PAA: messageKeys.proofAddApprover,
  // ASSET APPROVALS
  AAA: messageKeys.assetApprovalAddApprover,
  AAR: messageKeys.assetApprovalAddReviewer,
  ARR: messageKeys.assetApprovalRemoveReviewer,
  ARA: messageKeys.assetApprovalRemoveApprover,
  ATA: messageKeys.assetApprovalAddTeamApprover,
  ATR: messageKeys.assetApprovalAddTeamReviewer,
  RTA: messageKeys.assetApprovalRemoveTeamApprover,
  RTR: messageKeys.assetApprovalRemoveTeamReviewer,
  ADA: messageKeys.assetApprovalApproved,
  ADC: messageKeys.assetApprovalChangesApproved,
  ADN: messageKeys.assetApprovalNeedsWork,
  AAN: messageKeys.assetApprovalDecisionNotNeededApproved,
  ACN: messageKeys.assetApprovalDecisionNotNeededChangesApproved,
  AWN: messageKeys.assetApprovalDecisionNotNeededNeedsWork,
  AUT: messageKeys.assetApprovalDecisionUserOnTeam,
  AAC: messageKeys.assetApprovalsApproverChanged,
  ARC: messageKeys.assetApprovalsReviewerChanged,
  RDC: messageKeys.assetApprovalsReviewerDecisionComplete,
  TRC: messageKeys.assetApprovalsReviewerTeamDecisionComplete,
  TRN: messageKeys.assetApprovalsReviewerTeamDecisionNotNeeded
}

const eventArgsMap = {
  //NOTE CODES
  HRC: null,
  M: null,
  SUP: null,
  T: null,
  WIC: null,
  WRR: null,
  TCA: null,
  TCO: null,
  UC: null,
  //ANNOUCEMENT CODES
  ANC: 'announcement',
  //ASSIGNMENT
  ASN: null,
  AST: 'assignee',
  // ACCESS REQUESTS
  TA: 'accessRequestAction',
  // DOCUMENT REQUESTS
  DRC: null,
  DRF: null,
  DRR: null,
  // DOCUMENT SHARE
  DST: 'assignee',
  DSU: null,
  DSP: null,
  DUV: null,
  // JOURNAL ENTRY
  CDC: null,
  PDC: null,
  TSA: null,
  TSO: null,
  TSR: null,
  TSS: null,
  LKN: null,
  // WAITING APPROVAL
  AWT: null,
  // DOCUMENT APPROVAL
  DAU: null,
  DAA: null,
  DAC: null,
  DAH: null,
  DAR: null,
  //PROOFING
  PAE: null,
  PAA: null,
  // ASSET APPROVAL
  AAA: null,
  AAR: null,
  ARR: null,
  ARA: null,
  ADA: null,
  ADC: null,
  ADN: null,
  AAN: ['actorName', 'documentName', 'documentVersion', 'decision'],
  ACN: ['actorName', 'documentName', 'documentVersion', 'decision'],
  AWN: ['actorName', 'documentName', 'documentVersion', 'decision'],
  AUT: ['actorName', 'teamName'],
  ATA: ['teamName'],
  ATR: ['teamName'],
  RTA: ['teamName'],
  RTR: ['teamName'],
  AAC: null,
  ARC: null,
  RDC: null,
  TRC: null,
  TRN: ['actorName', 'documentName', 'documentVersion']
}

const objCodeToMessage = {
  [Announcement]: 'announcement',
  [Document]: 'docu',
  [DocumentVersion]: 'docv',
  [Iteration]: 'iteration',
  [Note]: 'note',
  [OpTask]: 'optask',
  [PortalSection]: 'portalsection',
  [PortalTab]: 'portaltab',
  [Portfolio]: 'port',
  [Program]: 'prgm',
  [Project]: 'proj',
  [Proof]: 'home.proof',
  [ProofApproval]: null,
  [RateCard]: 'rtcrd',
  [Task]: 'task',
  [Team]: 'team',
  [Timesheet]: 'tshet',
  [User]: 'user',
  [Template]: 'tmpl',
}

function getArgs(eventType, item) {
  const argsInfo = eventArgsMap[eventType]
  if (argsInfo != null && item) {
    if (argsInfo === 'assignee' && item.assignee) {
      return [item.assignee.name]
    } else if (argsInfo === 'accessRequestAction' && item.accessRequestAction) {
      return [item.accessRequestAction]
    } else if (item.args) {
      return argsInfo.map(arg => item.args[arg])
    }
  }

  return []
}

const argsEventTypesExcludeParentMessageKey = new Set(['DST','AAN','ACN','AWN', 'AUT', 'ATA', 'ATR', 'RTA', 'RTR', 'TRN'])

function addParentMessageKeyToArgs(parentMessage, args, eventType) {
  if (eventType && argsEventTypesExcludeParentMessageKey.has(eventType)) return args

  if (parentMessage && args && parentMessage !== args[0]) {
    args.splice(0, -1, parentMessage)
  }

  return args
}

export default function useTypeText(eventType, item) {
  const [text, setText] = useState(null)
  const args = getArgs(eventType, item)
  const argMessageKey = objCodeToMessage[item?.objCode]
  const messageKey = eventMap[eventType]

  if (messageKey == null || argMessageKey == null) {
    // Logging to help trace down unsupported events so we can add supported messages.
    logger.warn('UNSUPPORTED NOTIFICATION TYPE')
    logger.warn(`eventType: "${eventType}"`)
    logger.warn(
      `item: "${JSON.stringify({
        objCode: item?.objCode,
        owner: { ID: item?.owner?.ID },
        parent: { objCode: item?.parent?.objCode, ID: item?.parent?.ID },
        ID: item?.ID,
      })}"`
    )
  }

  useEffect(() => {
    async function getMessageText() {
      const argMessage = await localizationClient.getText(argMessageKey, fallbackMap[argMessageKey])
      if (messageKey != null) {
        const message = await localizationClient.getText(
          messageKey,
          fallbackMap[messageKey],
          ...(args.length > 0
            ? addParentMessageKeyToArgs(argMessage, args, eventType)
            : [argMessage])
        )

        setText(message)
      }
    }

    getMessageText()
  }, [eventType, messageKey, argMessageKey, args])

  return text
}
