import React from 'react'
import { bool, func } from 'prop-types'
import { css } from 'emotion'
import { useUpdateAtom } from 'jotai/utils'
import { AccessRequest } from 'workfront-objcodes'
import { primary } from '@phoenix/all'
import { markNotificationReadAtom, markNotificationUnreadAtom } from '../../atoms/notifications'
import { notificationShape } from '../../utils/prop-types'
import useTypeText from '../../utils/use-type-text'
import { NotificationItem } from './notification-item'
import { SupportRequestItem } from './support-request-item'
import {
  ASSET_APPROVAL_DECISION_EVENT_TYPES,
  ASSET_APPROVAL_TEAM_EVENTS,
} from '../../utils/constants'

export const itemStyle = css`
  border-bottom: 1px solid ${primary.gray(200)};

  &:last-of-type {
    border-bottom: none;
  }
`

export default function TypedNotificationItem({ condensedDisplay, notification, toggleHubShown }) {
  const { item, notificationID, ID, isRead, entryDate, eventType } = notification
  const markNotificationRead = useUpdateAtom(markNotificationReadAtom)
  const markNotificationUnread = useUpdateAtom(markNotificationUnreadAtom)
  const type = useTypeText(eventType, item)
  const fromForTeam = useTypeText(ASSET_APPROVAL_TEAM_EVENTS.userOnTeam, item)

  if (item == null) return null

  const from =
    item.eventMetaData?.teamName && ASSET_APPROVAL_DECISION_EVENT_TYPES.includes(eventType)
      ? fromForTeam
      : item.owner?.name
  const { text, title, owner, parent, link, objCode, documents, allowExternalLinking } = item
  const isSupportRequest = notification.objCode === AccessRequest && eventType === 'CSA'

  function handleToggleRead() {
    if (!isRead) {
      markNotificationRead([notificationID])
    } else {
      markNotificationUnread(notificationID)
    }
  }

  return (
    <li className={itemStyle}>
      {isSupportRequest && (
        <SupportRequestItem
          avatar={owner?.avatar}
          condensedDisplay={condensedDisplay}
          ID={ID}
          isRead={isRead}
          text={text}
          time={entryDate}
        />
      )}
      {!isSupportRequest && (
        <NotificationItem
          avatar={owner?.avatar}
          condensedDisplay={condensedDisplay}
          description={text}
          documents={documents}
          eventType={eventType}
          from={from}
          href={link}
          ID={ID}
          isRead={isRead}
          objCode={objCode}
          parent={parent}
          time={entryDate}
          title={title}
          toggleHub={toggleHubShown}
          toggleRead={handleToggleRead}
          type={type}
          allowExternalLinking={allowExternalLinking}
        />
      )}
    </li>
  )
}

TypedNotificationItem.propTypes = {
  condensedDisplay: bool,
  notification: notificationShape.isRequired,
  toggleHubShown: func,
}
