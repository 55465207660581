import parseParent from './parent'

export default function parseProofApproval(note) {
  const item = {
    ...note,
    link: `${note.proofUrl}`,
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}
