import { atom } from 'jotai'
import { grantSupportRequest } from '../api/support-request'
import { notificationsAtom, totalUnreadNotificationsAtom } from './notifications'

export const showSupportConfirmAtom = atom(false)
export const supportRequestDataAtom = atom({ ID: null, text: null })

export const confirmSupportRequestAtom = atom(null, async (get, set, accessGranted) => {
  const supportRequestData = get(supportRequestDataAtom)
  await grantSupportRequest(supportRequestData.ID, accessGranted)

  set(
    notificationsAtom,
    get(notificationsAtom).map((notification) => {
      if (supportRequestData.ID === notification.notificationID) {
        return { ...notification, isRead: false }
      }
      return notification
    })
  )
  set(totalUnreadNotificationsAtom, get(totalUnreadNotificationsAtom) - 1)
  set(showSupportConfirmAtom, false)
})
