import React from 'react'
import { func } from 'prop-types'
import { css, tw, tx } from 'workfront-twind'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { useDebouncedCallback } from 'use-debounce'
import { Tooltip } from '@phoenix/all'
import { getMessageParam, useLocalization } from '@productivity/i18n'
import { addTestId } from '@productivity/utils'
import {
  fetchNotificationsAtom,
  notificationFetchStateAtom,
  totalUnreadNotificationsAtom,
} from '../../atoms/notifications'
import { fetchStates, FETCH_THROTTLE, testIds, messageKeys } from '../../utils/constants'
import useFirstOccurrence from '../../utils/use-first-occurrence'

const pillStates = {
  [fetchStates.LOADING]: (total, isInitialLoad) => {
    // Only show loading indicator on initial load
    if (isInitialLoad === false) return pillStates.success(total)

    return {
      content: '?',
    }
  },
  [fetchStates.SUCCESS]: (total) => ({
    content: total,
  }),
  [fetchStates.ERROR]: () => ({
    content: '!',
  }),
}

export default function NotificationPill({ toggleHubShown }) {
  const fetchingStatus = useAtomValue(notificationFetchStateAtom)
  const total = useAtomValue(totalUnreadNotificationsAtom)
  const [notificationCountLabel] = useLocalization(
    ...getMessageParam(messageKeys.notificationsCount, [total])
  )
  const isInitialLoad = useFirstOccurrence(fetchingStatus, fetchStates.LOADING)
  const { content } = pillStates[fetchingStatus](total, isInitialLoad)
  const fetchNotifications = useUpdateAtom(fetchNotificationsAtom)
  const debouncedFetchNotifications = useDebouncedCallback(fetchNotifications, FETCH_THROTTLE)

  // These classes should be kept in sync with those in @wf-mfe/navigation NotificationPill
  let btnClasses = 'border border-solid border-gray-300 text-gray-300'

  if (fetchingStatus === fetchStates.ERROR) {
    btnClasses = 'border border-solid border-red-500 text-red-500'
  } else if (total !== undefined || fetchingStatus !== fetchStates.LOADING) {
    btnClasses = total > 0 ? 'bg-blue-900 text-white' : 'border border-solid border-gray text-gray'
  }

  return (
    <Tooltip
      className={tw(tooltipContainerClassName)}
      content={notificationCountLabel}
      onMouseEnter={() => debouncedFetchNotifications()}
      testID={testIds.pillTooltip}
    >
      <button
        {...addTestId(testIds.pill)}
        aria-label={notificationCountLabel}
        className={tx('font-bold rounded px-1 text-75 flex items-center justify-center', btnClasses)}
        style={{ height: '1.25rem', minWidth: '1.25rem' }}
        onClick={() => {
          // We don't have an error ui in Notification List yet
          if (fetchingStatus !== fetchStates.ERROR) {
            toggleHubShown()
          }
        }}
      >
        <span>{content}</span>
      </button>
    </Tooltip>
  )
}

NotificationPill.propTypes = { toggleHubShown: func.isRequired }

const tooltipContainerClassName = css`
  display: block;
  min-width: 1.25rem;
  height: 1.25rem;
`
