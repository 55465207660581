import parseParent from "./parent";
import {
  ASSET_APPROVAL_DECISION_EVENT_TYPES,
  ASSET_APPROVAL_PARTICIPANT_TEAM_EVENT_TYPES,
  externalIntegrationTypes
} from "../constants";

export default function parseDocumentVersionApproval(note, notification) {
  const eventType = notification.eventType ? notification.eventType : null
  const documentID = note.documents?.length ? note.documents[0].ID : null
  const documentVersionID = note.documents?.length ? note.documents[0].currentVersionID : null
  const isFrameIODocument = documentVersionID ? isAssetFrameIODocument(note) : false
  const redirectURI = `/document/${documentID}`
  let redirectLink = (isFrameIODocument && note.eventMetaData.externalPreviewURL) ? note.eventMetaData.externalPreviewURL : `${redirectURI}/details`
  let args

  if (ASSET_APPROVAL_DECISION_EVENT_TYPES.includes(eventType)) {
    redirectLink = `${redirectURI}/approvals`
    args = setArgsForDocumentVersionApprovals(note)
  } else if(ASSET_APPROVAL_PARTICIPANT_TEAM_EVENT_TYPES.includes(eventType)) {
    args = setArgsForDocumentVersionApprovals(note)
  }

  const item = {
    ...note,
    args,
    allowExternalLinking : isFrameIODocument,
    link: redirectLink,
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}

function setArgsForDocumentVersionApprovals(note) {
  let args = []
  args.actorName = note.owner?.name
  args.documentName = note.documents[0]?.name
  args.documentVersion = 'v' + note.documents[0]?.lastVersionNum
  args.teamName = note.eventMetaData?.teamName ?? null

  return args
}

export function isAssetFrameIODocument(note) {
  const assetIntegrationType = note.eventMetaData.externalIntegrationType ?  note.eventMetaData.externalIntegrationType : null
  return assetIntegrationType === externalIntegrationTypes.FRAMEIO
}
