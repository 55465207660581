import React from 'react'
import { css } from 'emotion'
import { primary, Text } from '@phoenix/all'
import { getMessageParam, useLocalization } from '@productivity/i18n'
import { addTestId } from '@productivity/utils'
import { testIds, messageKeys } from '../utils/constants'

export const container = css`
  min-height: 100px;
  display: flex;
  flex: 1 1 100%;
  justify-content: center;
  align-items: center;
  background-color: ${primary.white()};
`

export const message = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  & p {
    font-size: 2rem;
    line-height: 1;
  }

  & span {
    font-size: 0.75rem;
    font-weight: bold;
  }
`

/**
 * No notifications empty state
 */
export default function EmptyList() {
  const [noNotifications] = useLocalization(...getMessageParam(messageKeys.noNotifications))
  return (
    <div {...addTestId(testIds.emptyMsg)} className={container}>
      <div className={message}>
        <p>{'🎉'}</p>
        <Text>{noNotifications}</Text>
      </div>
    </div>
  )
}
