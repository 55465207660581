import React from 'react'
import { bool, string } from 'prop-types'
import { cx } from 'emotion'
import { format, parseISO } from 'date-fns'
import { useUpdateAtom } from 'jotai/utils'
import { Avatar, Button, Text, Tooltip } from '@phoenix/all'
import { getMessageParams, useLocalizations } from '@productivity/i18n'
import { addTestId, checkForEnterKeyPress } from '@productivity//utils'
import { showSupportConfirmAtom, supportRequestDataAtom } from '../../atoms/support-request'
import { testIds, messageKeys } from '../../utils/constants'
import { formatTimestamp } from '../../utils/timestamp-helper'
import * as styles from './styles'

export function SupportRequestItem({ avatar, condensedDisplay = false, ID, text, time }) {
  const [labels] = useLocalizations(
    getMessageParams({
      actionNeeded: messageKeys.actionNeeded,
      cta: messageKeys.supportRequestCta,
      notificationText: messageKeys.supportRequest,
      notification: messageKeys.notification,
      yesterday: messageKeys.yesterday,
    })
  )
  const setShowSupportConfirm = useUpdateAtom(showSupportConfirmAtom)
  const setSupportRequestData = useUpdateAtom(supportRequestDataAtom)
  const day = parseISO(time)

  const showRequestDialog = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setShowSupportConfirm(true)
    setSupportRequestData({ ID, text })
  }

  return (
    <div
      {...addTestId(`${testIds.notificationItem}${ID}`)}
      aria-label={labels.notification}
      className={cx(styles.wrapper, condensedDisplay && styles.condensedPadding)}
      onClick={showRequestDialog}
      onKeyDown={(event) => {
        if (checkForEnterKeyPress(event.keyCode)) {
          showRequestDialog(event)
        }
      }}
      role="presentation"
    >
      <div className={cx(styles.avatar, styles.supportAvatar)}>
        <Avatar showTooltip={false} type="medium" avatarURL={avatar} imageTabIndex={-1} />
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <div>
            <Text
              {...addTestId(testIds.titleLink)}
              className={cx(styles.title, styles.supportTitle)}
            >
              {labels.actionNeeded}
            </Text>
          </div>
          <Tooltip content={format(day, 'ddd, MMM d, h:mm a')} css="display: inherit;">
            <Text.Small>{formatTimestamp(day, labels.yesterday)}</Text.Small>
          </Tooltip>
        </div>
        <Text tagName="p">{labels.notificationText}</Text>
        <div className={styles.footer}>
          <Button onClick={showRequestDialog} text>
            {labels.cta}
          </Button>
        </div>
      </div>
    </div>
  )
}

SupportRequestItem.propTypes = {
  avatar: string,
  condensedDisplay: bool,
  ID: string,
  text: string,
  time: string,
}
