import parseParent from './parent'

export default function parseDocumentRequest(note, notification) {
  const item = {
    ...note,
    link: `/document/request/${notification.ID}`,
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}
