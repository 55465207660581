import { createReactLifecycles } from 'single-spa-workfront'
import NotificationCenter from './notification-center'
import { ErrorBoundary } from './utils/logger'

export { default as NotificationHub } from './notification-hub'
export { default as AnnouncementsPage } from './notification-center/shims/announcements'
export { NotificationsPage } from './notification-center'

export const { bootstrap, mount, unmount } = createReactLifecycles({
  errorBoundary: ErrorBoundary,
  rootComponent: NotificationCenter,
})
