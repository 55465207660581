import React from 'react'
import { useAtom } from 'jotai'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { ConfirmDialog, Text } from '@phoenix/all'
import { getMessageParams, useLocalizations } from '@productivity/i18n'
import {
  confirmSupportRequestAtom,
  showSupportConfirmAtom,
  supportRequestDataAtom,
} from '../atoms/support-request'
import { messageKeys } from '../utils/constants'

export function SupportRequestDialog() {
  const confirmSupportRequest = useUpdateAtom(confirmSupportRequestAtom)
  const { text } = useAtomValue(supportRequestDataAtom)
  const [showSupportConfirm, setShowSupportConfirm] = useAtom(showSupportConfirmAtom)
  const [labels] = useLocalizations(
    getMessageParams({
      body: messageKeys.supportRequestConfirmBody,
      deny: messageKeys.supportRequestConfirmDeny,
      grant: messageKeys.supportRequestConfirmGrant,
      header: messageKeys.supportRequestConfirmHeader,
    })
  )

  if (!showSupportConfirm) {
    return null
  }

  return (
    <ConfirmDialog
      confirmProps={{
        onClick: () => confirmSupportRequest(true),
      }}
      confirmText={labels.grant}
      denyProps={{
        onClick: () => confirmSupportRequest(false),
      }}
      denyText={labels.deny}
      escapeExits
      header={labels.header}
      medium
      onDenyClick={() => setShowSupportConfirm(false)}
    >
      <Text>{text ? text : labels.body}</Text>
    </ConfirmDialog>
  )
}
