import adobeWorkfrontLionDataUri from 'phoenix-icons/dist/adobeWorkfrontLionDataUri.js'
import { LION_ICON_DIMENSIONS, routes } from '../constants'

export default function parseAnnouncement(anc) {
  const { ID, isSystemAnnouncement, owner } = anc
  const avatar = isSystemAnnouncement
    ? adobeWorkfrontLionDataUri(LION_ICON_DIMENSIONS)
    : owner?.avatar

  return {
    ...anc,
    owner: {
      ...owner,
      // TODO: Tennet logo to all others
      avatar,
    },
    // TODO: This doesn't link to anything
    // Announcement isn't supported yet by getObjectLink
    // https://gitlab.workfront.tech/marvel/RouterObjCodes/blob/master/src/RouterObjCodes.js
    // link: getObjectLink({ ID: anc.ID, objCode: anc.objCode }).to,
    link: `${routes.announcementsLink}/${ID}`,
  }
}
