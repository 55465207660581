import parseParent from './parent'
import parseLink from './parse-link'

export default function parseAssignment(note) {
  let assignee = []
  if (note.assignee !== undefined) assignee.push(note.assignee.name)
  const item = {
    ...note,
    link: parseLink(note.ID, note.objCode),
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}
