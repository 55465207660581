import React, { useEffect, useState } from 'react'
import { bool, func } from 'prop-types'
import { css } from 'emotion'
import FocusTrap from 'focus-trap-react'
import { useAtomValue, useUpdateAtom } from 'jotai/utils'
import { HandleClickOutside, dropshadow, primary } from '@phoenix/all'
import {
  getMessageParam,
  getMessageParams,
  useLocalization,
  useLocalizations,
} from '@productivity/i18n'
import { addTestId, checkForEnterKeyPress, pxToRem } from '@productivity/utils'
import {
  fetchNotificationsAtom,
  notificationsAtom,
  notificationFetchStateAtom,
  totalUnreadNotificationsAtom,
} from '../atoms/notifications'
import EmptyList from '../shared/empty-list'
import FetchingBar from '../shared/fetching-bar'
import MarkAllHeader from '../shared/mark-all-header'
import NotificationLink from '../shared/notification-link'
import TypedNotificationItem from '../shared/notification-item'
import { SupportRequestDialog } from '../shared/support-request-dialog'
import { fetchStates, messageKeys, routes, testIds } from '../utils/constants'
import NotificationPill from './notification-pill'

const greyBorder = `1px solid ${primary.gray(200)}`

const styles = {
  container: css`
    position: relative;
    height: ${pxToRem(21)};
    width: ${pxToRem(24)};
  `,
  footerLink: css`
    font-weight: 500;
    line-height: 1;
  `,
  notificationsFooter: css`
    position: sticky;
    bottom: 0;
    height: 2rem;
    width: 100%;
    border-top: ${greyBorder};
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
  `,
  notificationsList: css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 9rem;
    /* Prevent notification pane from overflowing on screens with small viewport height */
    max-height: 80vh;
    width: 26.25rem;
    background-color: ${primary.white()};
    border: ${greyBorder};
    ${dropshadow.MEDIUM}
    z-index: 100;
    top: ${pxToRem(32)};
    right: ${pxToRem(-26)};

    ::after {
      position: absolute;
      content: '';
      right: ${pxToRem(32)};
      top: -6px;
      border: ${greyBorder};
      width: ${pxToRem(16)};
      height: ${pxToRem(16)};
      background-color: ${primary.gray(100)};
      transform: rotate(45deg);
      z-index: -1;
    }
  `,
  itemWrapper: css`
    width: 100%;
    max-height: 832px;
    overflow-y: scroll;
    overflow-x: hidden;
    /** Smooooth scroll on iOS */
    -webkit-overflow-scrolling: scroll;
  `,
  transparentBg: css`
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    width: 100%;
  `,
}

function NotificationsFooter({ toggleHubShown }) {
  const [labels] = useLocalizations(
    getMessageParams({
      allAnnouncements: messageKeys.allAnnouncements,
      allNotifications: messageKeys.allNotifications,
    })
  )
  return (
    <footer {...addTestId(testIds.listFooter)} className={styles.notificationsFooter}>
      <NotificationLink
        {...addTestId(testIds.announcementsLink)}
        to={routes.announcementsLink}
        className={styles.footerLink}
        onClick={toggleHubShown}
      >
        {labels.allAnnouncements}
      </NotificationLink>
      <NotificationLink
        {...addTestId(testIds.notificationsLink)}
        to={routes.notificationsLink}
        className={styles.footerLink}
        onClick={toggleHubShown}
      >
        {labels.allNotifications}
      </NotificationLink>
    </footer>
  )
}

NotificationsFooter.propTypes = { toggleHubShown: func.isRequired }

export default function NotificationHub({ defaultIsOpen = false }) {
  const [isOpen, setIsOpen] = useState(defaultIsOpen)
  const [notificationsMsg] = useLocalization(...getMessageParam(messageKeys.notifications))
  const fetchNotifications = useUpdateAtom(fetchNotificationsAtom)
  const fetchingStatus = useAtomValue(notificationFetchStateAtom)
  const notifications = useAtomValue(notificationsAtom)
  const total = useAtomValue(totalUnreadNotificationsAtom)
  const hasNotifications = total > 0

  useEffect(() => {
    if (defaultIsOpen) {
      return
    }

    fetchNotifications()
  }, [fetchNotifications, defaultIsOpen])

  return (
    <>
      <HandleClickOutside onOutsideClick={() => setIsOpen(false)} escapeListener>
        {() => (
          <div {...addTestId(testIds.hubContainer)} className={styles.container}>
            <NotificationPill toggleHubShown={() => setIsOpen(!isOpen)} />
            {isOpen && (
              <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                <div>
                  <div
                    {...addTestId(testIds.transparentOverlay)}
                    className={styles.transparentBg}
                    onClick={() => setIsOpen(false)}
                    onKeyDown={(event) => {
                      if (checkForEnterKeyPress(event.keyCode)) {
                        setIsOpen(false)
                      }
                    }}
                    role="presentation"
                  />
                  <section
                    {...addTestId(testIds.listContainer)}
                    className={styles.notificationsList}
                    role="dialog"
                    aria-modal="true"
                    aria-label={notificationsMsg}
                  >
                    <MarkAllHeader total={total} />
                    {fetchingStatus === fetchStates.LOADING && <FetchingBar />}
                    {hasNotifications && (
                      <ul {...addTestId(testIds.notificationsList)} className={styles.itemWrapper}>
                        {notifications
                          .filter((notification) => !notification.isRead)
                          .map((notification) => (
                            <TypedNotificationItem
                              key={notification.ID}
                              notification={notification}
                              toggleHubShown={() => setIsOpen(!isOpen)}
                            />
                          ))}
                      </ul>
                    )}
                    {!hasNotifications && <EmptyList />}
                    <NotificationsFooter toggleHubShown={() => setIsOpen(!isOpen)} />
                  </section>
                </div>
              </FocusTrap>
            )}
          </div>
        )}
      </HandleClickOutside>
      <SupportRequestDialog />
    </>
  )
}

NotificationHub.propTypes = { defaultIsOpen: bool }
