import { getObjectLink } from '@wf-mfe/navigation'
import parseParent from './parent'

export default function parseNote(note, notification) {
  const { ID: commentID } = notification
  const { ID, objCode } = note

  const item = {
    ...note,
    link: `${getObjectLink({ ID, objCode }).to}/updates?commentID=${commentID}&showCommentBox=true`,
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}
