import { useEffect, useRef } from 'react'

/**
 * Returns true if this is the first occurrence of the value
 */
export default function useFirstOccurrence(currentValue, valueToTrack) {
  const timesOccurred = useRef(0)

  if (currentValue === valueToTrack) ++timesOccurred.current

  useEffect(() => {
    // Reset if we have a new value to track
    timesOccurred.current = 0
  }, [valueToTrack])

  return timesOccurred.current === 1
}
