import parseParent from './parent'
import parseLink from './parse-link'

export default function parseDocumentApproval(note) {
  const item = {
    ...note,
    link: parseLink(note.ID, note.objCode),
  }

  // Parse parent if it exists
  if (note.parent) item.parent = parseParent(note.parent)

  return item
}
