import to from 'await-to-js'
import { atom } from 'jotai'
import { toast } from '@phoenix/all'
import { getMessageParam, localizationClient } from '@productivity/i18n'
import {
  getNotifications,
  markNotificationUnread,
  markNotificationsRead,
} from '../api/notifications'
import { fetchStates, messageKeys, NOTIFICATIONS_LIMIT } from '../utils/constants'
import parseNotification from '../utils/parse-notification'

export const notificationsAtom = atom([])
export const totalUnreadNotificationsAtom = atom(0)
export const notificationFetchStateAtom = atom(fetchStates.LOADING)

export const fetchNotificationsAtom = atom(
  null,
  async (get, set, { includeAll = false, limit = NOTIFICATIONS_LIMIT } = {}) => {
    if (get(notificationFetchStateAtom) !== fetchStates.LOADING) {
      set(notificationFetchStateAtom, fetchStates.LOADING)
    }

    const [error, responseData] = await to(getNotifications(limit, includeAll))

    if (error) {
      const errorMessage = await localizationClient.getText(
        ...getMessageParam(messageKeys.fetchErrorMsg)
      )
      toast.error(errorMessage)

      set(notificationFetchStateAtom, fetchStates.ERROR)
      return
    }
    set(notificationFetchStateAtom, fetchStates.SUCCESS)
    set(
      notificationsAtom,
      responseData?.notifications?.map((notification) => parseNotification(notification))
    )
    set(totalUnreadNotificationsAtom, responseData?.total)
  }
)

export const markNotificationReadAtom = atom(null, async (get, set, IDs) => {
  const originalNotifications = get(notificationsAtom)
  const originalTotal = get(totalUnreadNotificationsAtom)

  set(
    notificationsAtom,
    originalNotifications.map((notification) => {
      if (IDs.includes('*') || IDs.includes(notification.notificationID)) {
        return { ...notification, isRead: true }
      }
      return notification
    })
  )

  set(totalUnreadNotificationsAtom, IDs.includes('*') ? 0 : originalTotal - IDs.length)

  const [error] = await to(markNotificationsRead(IDs))

  if (error) {
    const errorMessage = await localizationClient.getText(
      ...getMessageParam(messageKeys.markSeenError)
    )
    toast.error(errorMessage)

    set(notificationsAtom, originalNotifications)
    set(totalUnreadNotificationsAtom, originalTotal)
    return
  }
})

export const markNotificationUnreadAtom = atom(null, async (get, set, ID) => {
  const originalNotifications = get(notificationsAtom)
  const originalTotal = get(totalUnreadNotificationsAtom)

  set(
    notificationsAtom,
    originalNotifications.map((notification) => {
      if (ID === notification.notificationID) {
        return { ...notification, isRead: false }
      }
      return notification
    })
  )

  set(totalUnreadNotificationsAtom, originalTotal + 1)

  const [error] = await to(markNotificationUnread(ID))

  if (error) {
    const errorMessage = await localizationClient.getText(
      ...getMessageParam(messageKeys.markUnseenError)
    )
    toast.error(errorMessage)

    set(notificationsAtom, originalNotifications)
    set(totalUnreadNotificationsAtom, originalTotal)
    return
  }
})
