export const fetchStates = {
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
}

export const testIds = {
  announcementsLink: 'all-announcements-link',
  avatarContainer: 'avatar-container',
  emptyMsg: 'empty-notifications-msg',
  fetchingBar: 'fetching-bar',
  hubContainer: 'notification-hub-container',
  listContainer: 'notifications-list-container',
  listFooter: 'notifications-footer',
  notificationItem: 'notification-item-',
  notificationItemUnread: 'notification-item-unread',
  notificationsLink: 'all-notifications-link',
  notificationsList: 'notifications-list',
  notificationsMarkAll: 'notifications-mark-all',
  notificationsMarkAllHeader: 'notifications-mark-all-header',
  parentLink: 'parent-link',
  pill: 'notification-count-pill',
  pillTooltip: 'notification-pill-tooltip',
  titleLink: 'title-link',
  transparentOverlay: 'transparent-overlay',
}

export const routes = {
  announcementsLink: '/announcements',
  notificationsLink: '/notifications',
}

export const messageKeys = {
  accessRequest: 'notification.quicksilver.ta',
  actionNeeded: 'notification.label.actionneeded',
  allAnnouncements: 'announcement.all',
  allNotifications: 'notification.all',
  announcement: 'announcement',
  assignmentToTeam: 'notification.quicksilver.ast',
  assignmentToUser: 'notification.quicksilver.asn',
  comment: 'notification.quicksilver.comment',
  commitDateChanged: 'notification.quicksilver.cdc',
  documentApproval: 'notification.quicksilver.dau',
  documentApprovalAddUserView: 'notification.quicksilver.duv',
  documentApprovalApproved: 'notification.quicksilver.daa',
  documentApprovalCancelled: 'notification.quicksilver.dac',
  documentApprovalRejected: 'notification.quicksilver.dar',
  documentApprovalWithChanges: 'notification.quicksilver.dah',
  documentSharedWithTeam: 'notification.quicksilver.dst',
  documentSharedWithYou: 'notification.quicksilver.dsu',
  fetchErrorMsg: 'notification.quicksilver.fetcherror',
  fetchMsg: 'notification.quicksilver.fetchingnotifications',
  helpRequestComment: 'notification.quicksilver.hrc',
  likes: 'notification.quicksilver.like',
  markAllSeen: 'notification.markseen',
  markSeen: 'notification.mark.one.seen',
  markSeenError: 'notification.markseen.error',
  markUnseen: 'notification.mark.one.unseen',
  markUnseenError: 'notification.markunseen.error',
  noNotifications: 'notification.quicksilver.nonotifications',
  notificationsCount: 'quicksilver.global.navigation.notifications.count',
  notificationsTitle: 'quicksilver.global.navigation.notifications',
  notification: 'notification',
  notifications: 'notification.plural',
  objectComment: 'notification.quicksilver.commentonobject',
  plannedDateChanged: 'notification.quicksilver.pdc',
  proofAddApprover: 'notification.quicksilver.paa',
  proofAddApproverNoRequester: 'notification.quicksilver.pae',
  proofSharedWithYou: 'notification.quicksilver.dsp',
  requestedDocument: 'notification.quicksilver.drr',
  requestedDocumentCanceled: 'notification.quicksilver.drc',
  requestedDocumentFulfilled: 'notification.quicksilver.drf',
  supportRequest: 'notification.supportrequest',
  supportRequestConfirmBody: 'notification.supportrequest.confirm.body',
  supportRequestConfirmDeny: 'notification.supportrequest.confirm.deny',
  supportRequestConfirmGrant: 'notification.supportrequest.confirm.grant',
  supportRequestConfirmHeader: 'notification.supportrequest.confirm.header',
  supportRequestCta: 'notification.supportrequest.cta',
  timesheetApproved: 'notification.quicksilver.tsa',
  timesheetComment: 'notification.quicksilver.timesheetcomment',
  timesheetRejected: 'notification.quicksilver.tsr',
  timesheetReopened: 'notification.quicksilver.tso',
  timesheetSubmitted: 'notification.quicksilver.tss',
  waitingApproval: 'notification.quicksilver.awt',
  yesterday: 'yesterday',
  assetApprovalAddApprover: 'notification.quicksilver.aaa',
  assetApprovalAddReviewer: 'notification.quicksilver.aar',
  assetApprovalRemoveApprover: 'notification.quicksilver.ara',
  assetApprovalRemoveReviewer: 'notification.quicksilver.arr',
  assetApprovalAddTeamApprover: 'notification.quicksilver.ata',
  assetApprovalAddTeamReviewer: 'notification.quicksilver.atr',
  assetApprovalRemoveTeamApprover: 'notification.quicksilver.rta',
  assetApprovalRemoveTeamReviewer: 'notification.quicksilver.rtr',
  assetApprovalApproved: 'notification.quicksilver.ada',
  assetApprovalChangesApproved: 'notification.quicksilver.adc',
  assetApprovalNeedsWork: 'notification.quicksilver.adn',
  assetApprovalDecisionNotNeededApproved: 'notification.quicksilver.aan',
  assetApprovalDecisionNotNeededChangesApproved: 'notification.quicksilver.acn',
  assetApprovalDecisionNotNeededNeedsWork: 'notification.quicksilver.awn',
  assetApprovalDecisionUserOnTeam: 'notification.quicksilver.aut',
  assetApprovalsApproverChanged: 'notification.quicksilver.aac',
  assetApprovalsReviewerChanged: 'notification.quicksilver.arc',
  assetApprovalsReviewerDecisionComplete: 'notification.quicksilver.rdc',
  assetApprovalsReviewerTeamDecisionComplete: 'notification.quicksilver.trc',
  assetApprovalsReviewerTeamDecisionNotNeeded: 'notification.quicksilver.trn',
}

export const ASSET_APPROVAL_DECISION_EVENT_TYPES = ['ADA','ADC','ADN','AAN','ACN','AWN', 'RDC', 'TRC', 'TRN']
export const ASSET_APPROVAL_PARTICIPANT_TEAM_EVENT_TYPES = ['ATA','ATR','RTA','RTR']
export const ASSET_APPROVAL_TEAM_EVENTS = {
  userOnTeam: 'AUT'
}
// 1.5 seconds
export const FETCH_THROTTLE = 1500

// UX approved limit for notifications list
export const NOTIFICATIONS_LIMIT = 80

export const LION_ICON_DIMENSIONS = { height: 24, width: 24 }

export const externalIntegrationTypes = {
  FRAMEIO : 'FRAMEIO'
}
